/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function () {
                // obfuscate links
                $(".obfuscate").on("click", function (e) {
                    e.preventDefault();
                    var el = $(this)[0];
                    el.href = "mailto:" + el.innerHTML.split("").reverse().join("");
                });
                // slick slider
                $(".gallery-full").each(function () {
                    var slider = $(this);
                    slider.imagesLoaded(function () {
                        slider.addClass("slick-window-initialized");
                    });
                });
                $(".slick-slider").each(function () {
                    var $gallery = $(this).parents(".gallery-full");
                    var $status = $gallery.find(".slider-paging");
                    var $slickElement = $(this);
                    $slickElement.on("init reInit afterChange", function (event, slick, currentSlide, nextSlide) {
                        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                        var i = (currentSlide ? currentSlide : 0) + 1;
                        $status.text(i + " / " + slick.slideCount);
                    });
                    //$('.slider').slick({
                    $slickElement.slick({
                        dots: false,
                        arrows: false,
                        appendArrows: $gallery.find(".slider-arrows"),
                        prevArrow: '<button type="button" class="slick-prev">previous</button>',
                        nextArrow: '<button type="button" class="slick-next">next</button>',
                        slidesToShow: 1,
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 543,
                                settings: {
                                    infinite: true,
                                    dots: false,
                                    arrows: true,
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                    centerMode: true,
                                    variableWidth: true,
                                },
                            },
                            {
                                breakpoint: 1200,
                                settings: {
                                    infinite: true,
                                    dots: false,
                                    arrows: true,
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                    centerMode: true,
                                    variableWidth: true,
                                },
                            },
                            {
                                breakpoint: 2500,
                                settings: {
                                    arrows: true,
                                    infinite: true,
                                    dots: false,
                                    slidesToShow: 3,
                                    slidesToScroll: 1,
                                    variableWidth: true,
                                    centerMode: true,
                                },
                            },
                        ],
                    });
                });

                // fluid video
                var fluid_vids = {
                    fluid_videos: function () {
                        var iframes = document.getElementsByTagName("iframe");
                        for (var i = 0; i < iframes.length; i++) {
                            var iframe = iframes[i],
                                players = /www.youtube.com|player.vimeo.com/;
                            if (iframe.src.search(players) > 0) {
                                var videoRatio = (iframe.height / iframe.width) * 100;
                                iframe.style.position = "absolute";
                                iframe.style.top = "0";
                                iframe.style.left = "0";
                                iframe.width = "100%";
                                iframe.height = "100%";
                                var wrap = document.createElement("div");
                                wrap.className = "fluid-vids";
                                wrap.style.width = "100%";
                                wrap.style.position = "relative";
                                wrap.style.paddingTop = videoRatio + "%";
                                var iframeParent = iframe.parentNode;
                                iframeParent.insertBefore(wrap, iframe);
                                wrap.appendChild(iframe);
                            }
                        }
                    },
                };
                fluid_vids.fluid_videos();

                // anchor links
                $("a[href*=#]").click(function (e) {
                    var id = $(this).attr("href");
                    var diff = $(".banner").outerHeight();
                    if (id !== "#") {
                        $("html,body")
                            .stop(true)
                            .animate(
                                {
                                    scrollTop: $(id).offset().top - diff,
                                },
                                300
                            );
                        e.preventDefault();
                    }
                });

                $('[data-toggle="tooltip"]').tooltip({
                    container: 'body',
                    trigger: "click",
                    placement: function (tt, trigger) {
                        var $trigger = $(trigger);
                        var $placement = $trigger.attr("data-placement");
                        if ($placement == "right" && $(window).width() < 768) {
                            return "bottom";
                        } else {
                            return $placement;
                        }
                    },
                });
                $('[data-toggle="tooltip"]').on('show.bs.tooltip', () => setTimeout(() => $('[data-toggle="tooltip"]').tooltip('update'), 50));

                $('.share-below [data-toggle="tooltip"]').on("show.bs.tooltip", function () {
                    $($(this).data("bs.tooltip").tip).addClass("footer-tip");
                });

                $(document).click(function (event) {
                    // hide share button popover
                    if (!$(event.target).closest('[data-toggle="tooltip"]').length) {
                        $('[data-toggle="tooltip"]').tooltip("hide");
                    }
                });

                // mobile p fix
                var p = $("p");
                p.each(function () {
                    if ($(this).html() == "&nbsp;") {
                        $(this).addClass("hidden-mobile");
                    }
                });

                if ($(".zitat-row").length) {
                    $(".content.row").addClass("noPaddingTablets");
                }

                // JavaScript to be fired on all pages
                var header = $(".banner");
                //var origOffsetY = header.outerHeight();
                var origOffsetY = 229;
                var content = $(".wrap");
                function doPos() {
                    var header = $(".banner");
                    var scrollNav = $(".scroll-nav");
                    var maxW = scrollNav.innerWidth();
                    var brother = $(".page-intro-detail");
                    var brotherPos = brother.offset();
                    var brotherW = brother.outerWidth();
                    var sisterW = $(".spacer-col").outerWidth();
                    var scrolled = $(window).scrollTop();
                    if (scrollNav.length) {
                        var zitatRowH = $(".zitat-row").height();
                        var scrollNavPos = scrollNav.offset();
                        var scrollNavoffsetX = brotherPos.left + brotherW + sisterW;
                        scrollNav.addClass("fixd").css({
                            left: scrollNavoffsetX,
                            top: $("header.fixed-top").height() * 2,
                            maxWidth: maxW,
                        });
                    }
                }
                function doHorPos() {
                    var header = $(".banner");
                    var scrollNav = $(".scroll-nav");
                    var maxW = $(".col-3.d-none").css("max-width");
                    var brother = $(".page-intro-detail");
                    var brotherPos = brother.offset();
                    var brotherW = brother.outerWidth();
                    var sisterW = $(".spacer-col").outerWidth();
                    var scrolled = $(window).scrollTop();
                    if (scrollNav.length) {
                        var zitatRowH = $(".zitat-row").height();
                        var scrollNavPos = scrollNav.offset();
                        var scrollNavoffsetX = brotherPos.left + brotherW + sisterW;
                        scrollNav.addClass("fixd").css({
                            left: scrollNavoffsetX,
                            maxWidth: maxW,
                        });
                    }
                }

                // top section fix
                $(".angebot-top-wrap").css({ minHeight: $(".angebot-top-wrap").height() });
                $(".white-top").eq(0).addClass("white-top-no-top");
                $(".white-top").last().addClass("white-top-bottom");

                // accordion wrap fix
                var coll = $(".collapse-wrap.col-md-6");
                coll.wrapAll('<div class="col-md-12 accordion-outer-wrap"><div class="row">');
                var half = Math.floor(coll.length / 2);
                coll.filter(function (i) {
                    return i < half;
                }).wrapAll('<div class="col-md-6">');
                coll.filter(function (i) {
                    return i >= half;
                }).wrapAll('<div class="col-md-6">');
                // faq
                var collfaq = $(".faq_row .col-md-6");
                collfaq.wrapAll('<div class="col-md-12 accordion-outer-wrap"><div class="row">');
                var half = Math.floor(collfaq.length / 2);
                collfaq
                    .filter(function (i) {
                        return i < half;
                    })
                    .wrapAll('<div class="col-md-6">');
                collfaq
                    .filter(function (i) {
                        return i >= half;
                    })
                    .wrapAll('<div class="col-md-6">');

                // Menu scroll function
                function doScroll() {
                    $(window).on("scroll", function () {
                        var $this = $(this);
                        var $diff = 120;
                        var winH = $(window).height();
                        var scrollPos = $(document).scrollTop();
                        var footerTop = $("footer").offset().top;
                        if ($(".home").length) {
                            $diff = 190;
                        }
                        if ($(".zitat-active").length) {
                            $diff = 92;
                        }
                        if ($this.scrollTop() >= origOffsetY - $diff) {
                            content.css({ paddingTop: origOffsetY });
                            header.addClass("fixed-top");
                        } else {
                            content.css({ paddingTop: 0 });
                            header.removeClass("fixed-top");
                        }
                        if (
                            $(".scroll-nav").length &&
                            $this.scrollTop() >=
                                $(".angebot-top-wrap").eq(0).offset().top - $(".banner.fixed-top").height() * 2
                        ) {
                            if (!$(".fixd").length && $(window).width() > 1024) {
                                doPos();
                            }
                        } else {
                            $(".scroll-nav").removeClass("fixd").css({
                                left: "auto",
                                top: "auto",
                            });
                        }
                        // Active scrollnav
                        if ($(".scroll-nav").length) {
                            $(".scroll-nav ul li a").each(function () {
                                var currLink = $(this);
                                var ths = $(this).parents("li");
                                var lastSection = $(".angebot-wrap").eq($(".angebot-wrap").length - 1);
                                var top =
                                    $("#"+currLink.data("id")).offset().top -
                                    $("header").outerHeight(true) -
                                    parseInt($("#"+currLink.data("id")).find(".angebot-wrap").css("paddingTop"));
                                if (top <= scrollPos && top + $("#"+currLink.data("id")).outerHeight(true) > scrollPos) {
                                    $(".scroll-nav li").not(ths).removeClass("active");
                                    ths.addClass("active");
                                }
                                if (ths.is(":first-child")) {
                                    if (0 <= scrollPos && 0 + $(".angebot-wrap").eq(1).outerHeight(true) > scrollPos) {
                                        $(".scroll-nav li").not(ths).removeClass("active");
                                        ths.addClass("active");
                                    }
                                }
                                // Footer touched!
                                var footerTop =
                                    lastSection.offset().top - lastSection.height() + $(window).height() / 2 + 60;
                                var toptop = $(document).scrollTop();
                                var diff = scrollPos - footerTop;
                                if (scrollPos > footerTop) {
                                    $(".scroll-nav").css("margin-top", 0 - diff);
                                } else {
                                    $(".scroll-nav").css("margin-top", 0);
                                }
                            });
                        }
                    });
                }

                function doSquares() {
                    $(".archive-section article.col-md-4").each(function () {
                        var H = $(this).find(".preview-img").height();
                        $(".lower", $(this)).css("min-height", H);
                    });
                }
                doSquares();

                $("html,body").animate(
                    {
                        scrollTop: 0,
                    },
                    0,
                    function () {
                        if ($(window).width() > 1024) {
                            doScroll();
                        }
                        // anchor links on page load (angebote-layout)
                        var hash = decodeURIComponent(location.hash);
                        if (hash != "") {
                            $("html, body").animate({ scrollTop: $(hash).offset().top - 90 }, 500);
                        }
                    }
                );
                $(window).on("resize", function () {
                    if ($(window).width() > 1024) {
                        doScroll();
                        doHorPos();
                    }
                    doSquares();
                });
                // Hide/show drop background on bootstrap events!
                $(".nav-primary").on("show.bs.dropdown", function () {
                    $(".drop").toggleClass("down");
                });
                $(".nav-primary").on("hide.bs.dropdown", function () {
                    $(".drop").toggleClass("down");
                });
                // Mobile dropdown toggle injection
                $(".dropdown-toggle").append("<div class='mobile-toggle'></div>");
                $(".nav-primary > div > ul").append("<div class='menu-toggle'></div>");
                $(".nav-primary").on("click", ".mobile-toggle", function (e) {
                    $(this).toggleClass("visToggle");
                    var _d = $(e.target).closest(".dropdown");
                    _d.addClass("show");
                    e.preventDefault();
                });
                $(".nav-primary").on("click", ".menu-toggle", function (e) {
                    $("header.banner").toggleClass("rollout");
                    if ($("header.banner.rollout").length) {
                        $("header.banner.rollout").css({ height: $(window).height() + 3 });
                    } else {
                        $("header.banner").removeAttr("style");
                    }
                    e.preventDefault();
                });
                $(".nav-primary").on("click", ".visToggle", function (e) {
                    var _d = $(e.target).closest(".dropdown");
                    _d.removeClass("show");
                    e.preventDefault();
                });
                // Nav hover overrides
                var timer;
                $("body")
                    .on("mouseenter", "header .dropdown", function (e) {
                        clearTimeout(timer);
                        var _d = $(e.target).closest(".dropdown");
                        var lft = Math.abs($(".menu-menu-1-container").offset().left - _d.offset().left);
                        var realLft = $(".menu-menu-1-container").width() - lft - _d.width();
                        if ($(window).width() > 1024) {
                            _d.find(">div").css({ paddingRight: realLft });
                        } else {
                            _d.find(">div").css({ paddingRight: "0px" });
                        }
                        $(".dropdown-menu > div").removeClass("transitioned fast");
                        if ($(window).width() > 1024) {
                            if (!$(".drop.down").length) {
                                $(".dropdown-menu > div", _d).addClass("transitioned");
                            } else {
                                $(".dropdown-menu > div", _d).addClass("transitioned fast");
                            }
                            $(".drop").addClass("down");
                            $(".dropdown").not(_d).removeClass("show");
                            $(".dropdown-menu").removeClass("show");
                            _d.addClass("show");
                            $(".dropdown-menu", _d).addClass("show");
                        }
                    })
                    .on("mouseleave", "header .dropdown", function (e) {
                        var _d = $(e.target).closest(".dropdown");
                        timer = setTimeout(function () {
                            _d.removeClass("show");
                            $(".dropdown-menu", _d).removeClass("show");
                            $(".dropdown-menu > div", _d).removeClass("transitioned fast");
                            $(".drop").removeClass("down");
                        }, 1000);
                    });

                // Fixed Nav
                $(".scroll-nav ul li a").click(function (e) {
                    var lnk = $(this);
                    var ths = $(this).parents("li");
                    var top = $("#"+lnk.data('id')).offset().top - $("header").height();
                    if (ths.is(":first-child")) {
                        top = 0;
                    }
                    $("html, body").animate(
                        {
                            scrollTop: top,
                        },
                        500,
                        function () {
                            $(".scroll-nav li").not(this).removeClass("active");
                            ths.addClass("active");
                            history.replaceState(
                                "",
                                document.title,
                                window.location.pathname + window.location.search + "#" + lnk.data("id")
                            );
                        }
                    );
                    e.preventDefault();
                });
                $(".search-btn,.close-search").on("click", function (e) {
                    $(".search-overlay").toggleClass("actv");
                    if ($(".search-overlay.actv").length) {
                        $(".search-field").focus();
                    }
                    e.preventDefault();
                });

                // Last friday archive
                if ($("body.last-friday").length) {
                    var has_run = false;
                    var init_offset = 0;
                    if ($(".filter-post").length < 6) {
                        $("button.load-more-posts").hide();
                    }
                    $("button.load-more-posts").click(function (e) {
                        e.preventDefault();
                        var button = $(this);
                        // Disable button
                        button.prop("disabled", true);
                        // Record Nonce
                        var nonce = $(this).data("nonce");
                        if (has_run == false) {
                            button.data("offset", $(this).data("offset"));
                            init_offset = $(this).data("offset");
                        }
                        // Set AJAX parameters
                        data = {
                            action: "load_more_ajax",
                            init_offset: init_offset,
                            post_type: button.data("type"),
                            post_categories: [],
                            offset: button.data("offset"),
                            nonce: nonce,
                        };
                        $.post(load_more_ajax.ajaxurl, data, function (response) {
                            // Set Container Name
                            if(response){
                                var response = JSON.parse(response);
                                // Run through JSON
                                $.each(response, function (key, value) {
                                    // Set Value
                                    var val = $(value);
                                    // Set Container
                                    var $container = $(".archive-section");
                                    // Append Val
                                    $container.append(val);
                                });
                                // Undo Button Disable
                                button.prop("disabled", false);
                                // Set Offset
                                var offset = button.data("offset");
                                button.data("offset", offset + 10);
                                // If Has Run
                                has_run = true;
                                return false;
                            }
                        });
                    });
                }
                $("[data-fancybox]").fancybox({
                    buttons: ["close"],
                    arrows: true,
                    closeClickOutside: true,
                    infobar: false,
                    protect: true,
                    idleTime: 0,
                    btnTpl: {
                        arrowLeft:
                            '<a data-fancybox-prev class="prev_sl fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;">' +
                            "</a>",
                        arrowRight:
                            '<a data-fancybox-next class="next_sl fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;">' +
                            "</a>",
                        close:
                            '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
                            "</button>",
                    },
                    afterLoad: function (instance, current) {
                        var imageWidth = $(".fancybox-slide--current .fancybox-image").width();
                        $(".fancybox-caption").css("width", imageWidth);
                        $(".fancybox-content").addClass("opened-fx");
                    },
                    afterShow: function (instance, current) {
                        var imageWidth = $(".fancybox-slide--current .fancybox-image").width();
                        $(".fancybox-caption").css("width", imageWidth);
                    },
                    onInit: function () {
                        setTimeout(function () {
                            $(".fancybox-content").addClass("opened-fx");
                        }, 500);
                    },
                    beforeClose: function () {
                        $(".fancybox-content").removeClass("opened-fx").addClass("closing-fx");
                    },
                });
                $(window).resize(function () {
                    if ($(".fancybox-image").length) {
                        var imageWidth = $(".fancybox-slide--current .fancybox-image").width();
                        $(".fancybox-caption").css("width", imageWidth);
                    }
                });

                // ORGANIGRAMM
                var $grid = $(".org_wrap");
                $grid.isotope({
                    itemSelector: ".org_item",
                    transitionDuration: "0.2s",
                    getSortData: {
                        name: ".name",
                        fach: ".fach",
                    },
                    sortBy: "name",
                });
                $(".org-order").on("click", function (e) {
                    var filterValue = $(this).attr("href");
                    if (filterValue == "name") {
                        $grid.removeClass("filtered");
                    } else {
                        $grid.addClass("filtered");
                    }
                    $grid.isotope({ sortBy: filterValue });
                    e.preventDefault();
                });
                $(".org-filter").on("click", function (e) {
                    var filterValue = $(this).attr("href");
                    var filterName = $(this).text();
                    if (filterName.toLowerCase().replace("/s/g", "-") === "alle-anzeigen") {
                        filterName = "Kriterium auswählen";
                    }
                    if (filterValue == "*") {
                        $grid.removeClass("filtered");
                    } else {
                        $grid.addClass("filtered");
                    }
                    $grid.isotope({ filter: filterValue });
                    $(this).parents(".dropdown").find(".dropdown-toggle").html(filterName);
                    e.preventDefault();
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            },
        },
        // Home page
        home: {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            },
        },
        aktuell: {
            init: function () {
                if ($(".filter-post").length < 9) {
                    $("button.load-more-posts").hide();
                }

                var $grid = $(".archive-section");
                $grid.isotope({
                    itemSelector: ".filter-post",
                    transitionDuration: "0.2s",
                    masonry: {
                        layoutMode: "masonry",
                        columnWidth: ".grid-sizer",
                        gutter: 8,
                        percentPosition: true,
                    },
                });
                setTimeout(function () {
                    $grid.isotope("layout");
                }, 200);
                // filter items on button click
                $filters = $(".filter-button-group").on("click", "a", function () {
                    var $this = $(this);
                    var filterValue;
                    if ($this.is(".is-checked")) {
                        // uncheck
                        filterValue = "*";
                    } else {
                        filterValue = $(this).attr("data-filter");
                        $filters.find(".is-checked").removeClass("is-checked");
                    }
                    $this.toggleClass("is-checked");
                    if (filterValue == "*") {
                        $(".filter-button-group a").eq(0).addClass("is-checked");
                    }
                    $grid.isotope({ filter: filterValue });
                    $("button.load-more-posts").data('categories', $(this).attr("data-cat"));
                    $("button.load-more-posts").show();
                    $("button.load-more-posts").prop("disabled", false);
                    return false;
                });
                var has_run = false;
                var init_offset = 0;
                $("button.load-more-posts").click(function (e) {
                    e.preventDefault();
                    var button = $(this);
                    // Disable button
                    button.prop("disabled", true);
                    // Record Nonce
                    var nonce = $(this).data("nonce");
                    if (has_run == false) {
                        button.data("offset", $(this).data("offset"));
                        init_offset = $(this).data("offset");
                    }
                    // Set AJAX parameters
                    data = {
                        action: "load_more_ajax",
                        init_offset: init_offset,
                        post_type: button.data("type"),
                        post_categories: button.data("categories"),
                        offset: button.data("offset"),
                        nonce: nonce,
                    };
                    $.post(load_more_ajax.ajaxurl, data, function (response) {
                        if(response){
                            // Set Container Name
                            var response = JSON.parse(response);
                            // Run through JSON
                            $.each(response, function (key, value) {
                                // Set Value
                                var val = $(value);
                                // Set Container
                                var $container = $(".archive-section").isotope();
                                // Append Val
                                if(document.getElementsByClassName(val.attr("class")).length < 1){
                                    $container.append(val).isotope("appended", val);
                                }
                            });
                            // Undo Button Disable
                            button.prop("disabled", false);
                            // Set Offset
                            var offset = button.data("offset");
                            button.data("offset", offset + response.length);
                            // Reload grid
                            setTimeout(function () {
                                $(".archive-section").isotope("layout");
                            }, 200);
                            // If Has Run
                            has_run = true;
                            return false;
                        }
                    });
                });
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, "finalize");
            });

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
